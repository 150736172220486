import React from 'react';
import { graphql } from 'gatsby';
import Link from '../components/link';
import styled from 'styled-components';
import SEO from '../components/seo';
import COLORS from '../constants/colors';
import Layout from '../components/layout';
import EmailListSignUp from '../components/EmailListSignUp';

const PostTemplateEl = styled.div`
  max-width: 764px;
  margin: auto;
  padding: 6em 1.5em 4em;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${COLORS.baseColor};
    line-height: 1.3;
  }

  small {
    font-weight: bold;
    opacity: 0.5;
  }

  .tags {
    margin-bottom: 24px;

    .tag {
      font-size: 75%;
      color: ${COLORS.baseColorDarker};
      text-transform: capitalize;
    }
  }

  .post-body {
    color: ${COLORS.darkerGrey};
    line-height: 1.7;

    p {
      line-height: 1.9;
    }

    pre {
      margin-bottom: 32px;
    }

    code.language-text {
      background: ${COLORS.genericGrey};
      color: ${COLORS.anaemicRed};
      /* font-weight: bold; */
    }

    a {
      text-decoration: none;
      color: ${COLORS.baseColor};
      font-weight: bold;

      &:hover {
        color: ${COLORS.baseColorDarker};
      }
    }
  }

  .post-blurb {
    font-style: italic;
    font-weight: lighter;
  }

  .navigation-section {
    display: flex;

    .nav-container {
      width: 50%;
      text-align: right;

      &:first-child {
        text-align: left;
      }
    }
  }
`;

export default class PostTemplate extends React.Component {
  _initializeTags = (tags) => (typeof tags == 'string' ? tags.split(',') : []);
  render() {
    const { data, pageContext } = this.props;
    const { markdownRemark } = data;
    const { frontmatter, html } = markdownRemark;
    const { nextPostMeta, previousPostMeta } = pageContext;
    const tags = this._initializeTags(frontmatter.tags);
    const blurb = data.site.siteMetadata.postBlurb;

    return (
      <Layout>
        <SEO
          title={frontmatter.title}
          keywords={[
            `Babs Craig`,
            `Learn JavaScript`,
            `Babasanya Craig`,
            `Front End Development`,
            `React`,
            `JavaScript`,
            `Developer`,
          ]}
        />
        <PostTemplateEl>
          <h1>{frontmatter.title}</h1>
          <img alt="blogpost cover" src={frontmatter.cover_image} />
          <small>{frontmatter.date}</small>
          <section className="tags">
            {tags.map((tag, i, a) => (
              <span className="tag" key={tag}>
                {tag}
                {/* this is just to add a comma to all but the last tag */}
                {i === a.length - 1 ? '' : ','}
              </span>
            ))}
          </section>
          <div
            className="post-body"
            dangerouslySetInnerHTML={{ __html: html }}
          />
          <section
            className="post-blurb"
            dangerouslySetInnerHTML={{ __html: blurb }}
          />
          <section className="navigation-section">
            <div className="nav-container">
              {previousPostMeta && (
                <Link
                  to={previousPostMeta.path}
                  name={`← ${previousPostMeta.title}`}
                />
              )}
            </div>
            <div className="nav-container">
              {nextPostMeta && (
                <Link to={nextPostMeta.path} name={`${nextPostMeta.title} →`} />
              )}
            </div>
          </section>
          <EmailListSignUp />
        </PostTemplateEl>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query($path: String!) {
    site {
      siteMetadata {
        postBlurb
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date
        title
        cover_image
        tags
      }
    }
  }
`;
